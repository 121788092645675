.Resume {
  padding: 10px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
.timeline-centered {
  position: relative;
  margin-bottom: 30px;
}

  .timeline-centered:before, .timeline-centered:after {
      content: " ";
      display: table;
  }

  .timeline-centered:after {
      clear: both;
  }

  .timeline-centered:before, .timeline-centered:after {
      content: " ";
      display: table;
  }

  .timeline-centered:after {
      clear: both;
  }

  .timeline-centered:before {
      content: '';
      position: absolute;
      display: block;
      width: 4px;
      background: #000000;
      left: 20%;
      top: 20px;
      bottom: 20px;
      margin-left: -4px;
  }

  .timeline-centered .timeline-entry {
    position: relative;
    width: 80%;
    float: right;
    margin-bottom: 70px;
    clear: both;
}

.timeline-centered .timeline-entry .timeline-entry-inner {
  position: relative;
  margin-left: -22px;
}

  .timeline-centered .timeline-entry .timeline-entry-inner:before, .timeline-centered .timeline-entry .timeline-entry-inner:after {
      content: " ";
      display: table;
  }

  .timeline-centered .timeline-entry .timeline-entry-inner:after {
      clear: both;
  }

  .timeline-centered .timeline-entry .timeline-entry-inner:before, .timeline-centered .timeline-entry .timeline-entry-inner:after {
      content: " ";
      display: table;
  }

  .timeline-centered .timeline-entry .timeline-entry-inner:after {
      clear: both;
  }

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-time {
  position: absolute;
  left: -100px;
  text-align: right;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span {
      display: block;
      font-size: 15px;
      font-weight: bold;
  }

  .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time:after {
    content: 'd';
    background: #000;
    
    display: block;
    width: 40px;
    height: 40px;
    border: 6px solid white;
    
    border-radius: 20px;
    text-align: center;
  
  
    line-height: 40px;
    font-size: 15px;
    float: left;
}


ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 290px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
} */

.timeline {
  display: flex;
  flex-direction: column;
}

/* .timeline::before {
  content: '';
  position: absolute;
  display: block;
  width: 4px;
  background: #000000;
  left: 200px;
  top: 20px;
  bottom: 20px;
  margin-left: -2px;
} */

.timeline-entry {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.timeline-time {
  order: 1;
  flex: 0 0 220px;
  line-height: 20px;
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
  font-size: 10pt;
}

.timeline-pad1 {
  order: 1;
  flex: 0 0 20px;
  line-height: 20px;
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
  font-size: 10pt;
}

.timeline-separator {
  order: 2;
  flex: 0 0 20px;
  position: relative;
}

.timeline-separator::before {
  background-color: lightskyblue;
  width: 4px;
  content: '';
  position: absolute;
  height: 100%;
  left: 50%;
  margin-left: -2px;
}

.badge-tags {
  color: #ffffff;
  background-color: lightskyblue;
}

.timeline-separator::after {
  background-color: black;
  width: 20px;
  content: '';
  position: absolute;
  height: 20px;
  border: 4px solid white;
  border-radius: 10px;
  top: 10px;
}

.timeline-entry:first-child > .timeline-separator::before {
  margin-top: 4px;
}
.timeline-entry:last-child > .timeline-separator::before {
  height: 20px;
}

.timeline-content {
  order: 3;
  flex: 1 0 0px;
  padding-left: 10px;
  padding-right: 0px;
  padding-top: 10px;
  text-align: left;
}

.timeline-content-left {
  order: 1;
  text-align: right;
  padding-left: 0px;
  padding-right: 10px;
}

.timeline-content > h2 {
  font-size: 12pt;
  margin-bottom: 0.2rem;
}

.timeline-content > h3 {
  font-size: 10pt;
  color: gray;
}

.timeline-content > p {
  font-size: 12pt;
  text-align: justify;
}

.timeline-content > p:last-child {
  text-align: left;
  line-height: 16pt;
}

.timeline-content.timeline-content-left > p:last-child {
  text-align: right;
}

@media print {
  body {-webkit-print-color-adjust: exact;}

  .badge-tags {
    border: 0px;
  }

  .timeline-time {
    flex: 0 0 160px;
    font-size: 8pt;
  }

  .timeline-content > h2 {
    font-size: 9pt;
    margin-bottom: 0.0rem;
  }
  
  .timeline-content > h3 {
    font-size: 8pt;
    margin-bottom: 0.2rem;
  }
  
  .timeline-content > p {
    font-size: 9pt;
    margin-bottom: 0.2rem;
  }

  .timeline-content > p:last-child {
    line-height: 13pt;
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.header-left {
  order: 1;
  flex: 0 0 220px;
  line-height: 20px;
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
  font-size: 10pt;
}

.header-separator {
  order: 2;
  flex: 0 0 20px;
  position: relative;
}

.header-separator::before {
  background-color: lightskyblue;
  width: 4px;
  content: '';
  position: absolute;
  height: 100%;
  left: 50%;
  margin-left: -2px;
}

.header-content {
  order: 3;
  flex: 1 0 0px;
  padding-left: 10px;
  padding-top: 10px;
  text-align: left;
}

.header-content > h2 {
  font-size: 30pt;
}

.header-content > h3 {
  font-size: 10pt;
  color: gray;
}

.header-content > p {
  font-size: 12pt;
  text-align: justify;
}

@media print {
  .header-left {
    flex: 0 0 160px;
    font-size: 8pt;
  }

  .header-content > h2 {
    font-size: 18pt;
    margin-bottom: 0.2rem;
  }
  
  .header-content > h3 {
    font-size: 8pt;
    margin-bottom: 0.2rem;
  }
  
  .header-content > p {
    font-size: 9pt;
    margin-bottom: 0.2rem;
  }
}

.section-separator {
  text-align: left;
  margin-left: 214px;
  font-size: 12pt;
  font-weight: bold;
  position: relative
}



.section-separator > span {
  color: gray;
}

@media print {
  .section-separator {
    margin-left: 154px;
  }
}

.section-separator2 {
  position: relative;
  height: 34px;
}

.separator2-head {
  position: absolute;
  margin-left: -16px;
  left: 50%;
  font-size: 12pt;
  font-weight: bold;
}
.separator2-head > span {
  color: gray;
}